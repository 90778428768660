import { SnapDialog } from '@ee-monorepo/shared/ui/dialog';
import { getgclCookie } from '@ee-monorepo/shared/utilities/functions';
import { useMerchantLongFormContext } from '@ee-monorepo/shared/context/merchant-form';
import { useTranslations } from 'next-intl';
import ConditionalRenderedForm from './conditional-rendered-form';
import { Button } from '@ee-monorepo/shared/ui/button';
import { useState, useRef, useEffect } from 'react';
import {
  MerchantBusinessFormData,
  MerchantFinancialFormData,
  MerchantLongFormInterface,
  MerchantProductsServicesFormData,
  SubmitDataHandle,
} from '@ee-monorepo/shared/utilities/types';
import { SharedUiMerchantSuccessBox } from '@ee-monorepo/shared/ui/merchant-success-box';
import { useHandHeld } from '@ee-monorepo/shared/utilities/hooks';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useMerchantLeadAPI } from '@ee-monorepo/shared/data-access/services';
import { useTrackingContext } from '@ee-monorepo/shared/context/tracking';

const formSteps: { [key: number]: string } = {
  0: 'ProductServiceData',
  1: 'BusinessData',
  2: 'FinancialData',
};

export function MerchantLongFormDialog() {
  const { tracking } = useTrackingContext();
  const isMobile = useHandHeld();
  const { show, setShow } = useMerchantLongFormContext();

  const t = useTranslations('dialogs_merchant_long_form_dialog');
  const [step, setStep] = useState(0);
  const [isValid, setIsValid] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [isCategoryOther, setIsCategoryOther] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const gclID = getgclCookie();
  const [finalData, setFinalData] = useState<MerchantLongFormInterface>(
    {} as MerchantLongFormInterface
  );
  const isFormStep = [0, 1, 2].includes(step);
  const childFormRef = useRef<SubmitDataHandle>(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const nextStep = () => {
    onTriggerFormHandler();
    const updatedSteps = step + 1;
    setStep(() => updatedSteps);
    triggerLogEvent('next');
  };

  const prevStep = () => {
    onTriggerFormHandler();
    const updatedSteps = step - 1;
    setStep(() => updatedSteps);
    triggerLogEvent('back');
  };

  const formNames: { [key: number]: string } = {
    0: t('labelStepperProductServices'),
    1: t('labelStepperBusiness'),
    2: t('labelStepperFinancials'),
  };

  const triggerLogEvent = (prevNext: 'next' | 'back') => {
    const formName = formNames[step];
    tracking?.logClickEvent({
      click_value: `${prevNext} - ${formName}`,
      click_type: 'cta',
      placement: 'new merchant application modal',
    });
  };

  const onTriggerFormHandler = () => {
    childFormRef?.current?.submitData();
  };

  const onSaveMerchantDataHandler = (
    formData:
      | MerchantProductsServicesFormData
      | MerchantBusinessFormData
      | MerchantFinancialFormData
  ) => {
    const _collectedData: MerchantLongFormInterface = finalData;
    _collectedData[formSteps[step]] = formData;
    setFinalData({ ..._collectedData });
    if (step === 0 && _collectedData) {
      const { ProductServiceData } = _collectedData;
      if (ProductServiceData?.industry === 'OTHER') {
        setIsCategoryOther(true);
      }
    }
    // check if other is selected and display short Form here
  };

  const onSuccess = () => {
    nextStep();
    setIsSubmitted(false);
    setSubmitError(false);
  };

  const onFailure = () => {
    setIsSubmitted(false);
    setSubmitError(true);
  };

  const { createMerchantLead, createMerchantLeadLoading } = useMerchantLeadAPI(
    t,
    onSuccess,
    onFailure
  );

  const submitForm = () => {
    setIsSubmitted(true);
    onTriggerFormHandler();

    if (step === Object.keys(formSteps).length - 1) {
      //Submit to API
      const data = {
        formName: 'LONG_FORM',
        data: finalData,
      };

      createMerchantLead(data);
    }
  };

  const setOnValid = () => {
    setIsValid(false);
  };

  const setOnInValid = () => {
    setIsValid(true);
  };

  const onClose = () => {
    setShow(false);
    setStep(0);
    setFinalData({});
    setIsCategoryOther(false);
    setSubmitError(false);
    setSubmitSuccess(false);
    setIsSubmitted(false);
    const formName = formNames[step];
    tracking?.logClickEvent({
      click_value: formName ? `close - ${formName}` : 'close',
      click_type: 'button',
      placement: 'new merchant application modal',
    });
  };
  const isPending = isFormStep && !submitSuccess;
  const isSuccess = !isFormStep || submitSuccess;

  return (
    <SnapDialog
      dialogPaperClasses={isPending ? 'pb-24' : ''}
      dialogContentClasses={isPending ? 'h-[620px]' : ''}
      hideCloseButton={isSuccess}
      title={isPending ? t('dialogTitle') : ''}
      titleAlignment={'start'}
      id="merchant-long-form-dialog"
      data-testid="merchantLongFormDialog"
      open={show}
      maxWidth="md"
      fullScreenOnMobile={isPending}
      centered={true}
      onClose={() => onClose()}
      className="text-left"
      PaperProps={
        isSuccess
          ? {
              style: {
                background: 'linear-gradient(0deg, #FFF 70%, #1B844A 0%)',
              },
            }
          : {
              sx: {
                width: !isMobile && '65%',
              },
            }
      }
    >
      {isPending ? (
        <div className="flex flex-col h-full">
          <div className="md:flex-row h-full grid content-between w-full">
            <ConditionalRenderedForm
              step={step}
              finalData={finalData}
              ref={childFormRef}
              setOnValid={setOnValid}
              setOnInValid={setOnInValid}
              submitError={submitError}
              isCategoryOther={isCategoryOther}
              onSaveMerchantDataHandler={onSaveMerchantDataHandler}
              setSubmitError={setSubmitError}
              setSubmitSuccess={setSubmitSuccess}
            />
            {gclID && gclID !== '' ? (
              <input type="hidden" id="gcl_aw_cookie" value={gclID} />
            ) : null}
            {isFormStep && !isCategoryOther && (
              <div className="snap-row pt-8 absolute bottom-0 w-full left-0 bg-white p-6">
                <div className="w-full">
                  <div className="flex justify-between flex-col md:flex-row">
                    {!isMobile && (
                      <Button
                        data-testid="backButton"
                        variant="secondary"
                        type="submit"
                        onClick={prevStep}
                        disabled={step === 0}
                        className="mr-2 mt-2 md:mt-0"
                      >
                        {t('labelBack')}
                      </Button>
                    )}
                    {isMobile && step !== 0 && (
                      <div className="absolute top-0 left-0 py-3 px-6">
                        <ArrowBack onClick={prevStep} fontSize="medium" />
                      </div>
                    )}
                    <div
                      className={
                        isMobile
                          ? 'flex flex-col md:hidden px-4 py-3 bg-SnapWhite fixed bottom-0 left-0 right-0 z-10'
                          : 'mr-2 mt-2 md:mt-0'
                      }
                    >
                      {step !== 2 ? (
                        <Button
                          disabled={isValid}
                          data-testid="nextButton"
                          type="submit"
                          onClick={nextStep}
                        >
                          {t('labelNext')}
                        </Button>
                      ) : (
                        <Button
                          disabled={
                            isValid || createMerchantLeadLoading || isSubmitted
                          }
                          data-testid="submitButton"
                          type="submit"
                          onClick={submitForm}
                        >
                          {t('labelSubmit')}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : null}
      {isSuccess ? <SharedUiMerchantSuccessBox /> : null}
    </SnapDialog>
  );
}

export default MerchantLongFormDialog;
