import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { formSchema } from './form-validation';
import { FieldControl } from '@ee-monorepo/shared/ui/field-control';
import { FormattedTextField } from './lazy';
import { useTranslations } from 'next-intl';
import { useEffect, useImperativeHandle, forwardRef, useState } from 'react';
import {
  FormComponentProps,
  MerchantFinancialFormData,
  SubmitDataHandle,
} from '@ee-monorepo/shared/utilities/types';
import { SegmentedControl } from '@ee-monorepo/shared/ui/segmented-control';
import { useTrackingContext } from '@ee-monorepo/shared/context/tracking';

export type MerchantFinancialFormProps = {
  onSaveMerchantData?: (data: MerchantFinancialFormData) => void;
} & FormComponentProps<MerchantFinancialFormData>;

export const MerchantFinancialForm = forwardRef<
  SubmitDataHandle,
  MerchantFinancialFormProps
>((props: MerchantFinancialFormProps, ref) => {
  const { onValid, onInvalid, formData, onSaveMerchantData = () => {} } = props;

  const t = useTranslations('forms_merchant_financial_form');
  const { tracking } = useTrackingContext();

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    trigger,
    formState: { isValid },
  } = useForm<MerchantFinancialFormData>({
    defaultValues: formData || {
      revenue: '',
      ein: '',
    },
    mode: 'all',
    resolver: yupResolver(formSchema),
  });

  const financialDetails: { ui: string; api: string }[] = [
    { ui: 'Lessthan$500k', api: 'LESS_THAN_500K' },
    { ui: '$500kTo$2M', api: 'FROM_500K_TO_2M' },
    { ui: '$2MTo$5M', api: 'FROM_2M_TO_5M' },
    { ui: '$5MTo$25M', api: 'FROM_5M_TO_25M' },
    { ui: 'Greaterthan$25M', api: 'GREATER_THAN_25M' },
  ];

  const handleTabChange = (index: number) => {
    const selectedFinacialData = financialDetails[index];
    setValue('revenue', selectedFinacialData.api);
    setValue('activeFinancialIndex', index);
    trigger('revenue');
    tracking?.logClickEvent({
      click_value: t(selectedFinacialData.ui),
      click_type: 'button',
      placement: 'new merchant application modal',
    });
  };

  useEffect(() => {
    if (isValid && onValid) {
      onValid(getValues());
      onSaveMerchantData(getValues());
    }
    if (!isValid && onInvalid) {
      onInvalid(getValues());
    }
  }, [isValid, onValid, onInvalid, getValues]);

  const onSubmitHandler = async (data: MerchantFinancialFormData) => {
    // TODO: here goes API request
    onSaveMerchantData(data);
  };

  const submitData = handleSubmit(onSubmitHandler);

  useImperativeHandle(
    ref,
    () => {
      return {
        submitData,
      };
    },
    [submitData]
  );

  const checkKeyDown = (e) => {
    if (e.key === 'Enter' && !isValid && onInvalid) e.preventDefault();
  };

  return (
    <form
      data-testid="merchant-financial-form"
      onKeyDown={(e) => checkKeyDown(e)}
    >
      <h2 className="headingSHeavy md:headingMHeavy py-3">
        {t('gross_revenue_title')}
      </h2>
      <div className={cn('row mx-0', 'md:table-cell')}>
        <div
          className="col-12 px-0"
          data-testid="revenueSegControl"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <FieldControl
            fieldName="activeFinancialIndex"
            control={control}
            render={(field, invalid) => (
              <SegmentedControl
                data-testid="selectedFinancialText"
                stackOnMobile={true}
                items={Array.from(financialDetails, ({ ui }) => {
                  return { label: t(ui) };
                })}
                onSelect={handleTabChange}
                size="normal"
                activeItemIndex={field.value}
              />
            )}
          />
        </div>
      </div>
      <div className={cn('row mx-0', 'py-2')}>
        <div className="w-full md:w-1/2 px-0">
          <FieldControl
            fieldName="ein"
            control={control}
            render={(field, invalid) => (
              <FormattedTextField
                {...field}
                fullWidth
                className="ein-custom-input"
                label={t('einNumber')}
                error={invalid}
                mask="00-0000000"
                isPassword={false}
                maskedValue={true}
                data-testid="einNumber"
                inputProps={{ maxLength: 10 }}
                onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                    (e.target as HTMLInputElement)?.blur();
                  }
                }}
                onKeyDown={(e) => {
                  e.key === 'Enter' && e.preventDefault();
                }}
              />
            )}
          />
          <div>{t('helper_text')}</div>
        </div>
      </div>
    </form>
  );
});
