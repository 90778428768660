import { createTranslations } from '@ee-monorepo/shared/utilities/functions';

export * from './lib/merchant-financial-form';
export * from './lib/form-validation'

// form module translation
export const getTranslations = createTranslations(
  'forms_merchant_financial_form',
  (locale) => {
    return require(`./i18n/${locale}.json`);
  }
);
