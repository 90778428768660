import {
  MerchantFinancialFormData,
  REQUIRED,
} from '@ee-monorepo/shared/utilities/types';
import * as yup from 'yup';

export const formSchema: yup.SchemaOf<MerchantFinancialFormData> = yup
  .object()
  .shape(
    {
      activeFinancialIndex: yup.number().required(REQUIRED),
      revenue: yup.string().required(REQUIRED),
      // To validate ein only if entered
      ein: yup.string().when('ein', {
        is: (ein: string) => Boolean(ein),
        then: yup.string().einNumberValidation(),
      }),
    },
    [['ein', 'ein']]
  );
