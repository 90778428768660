import { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { SegmentedControl } from '@ee-monorepo/shared/ui/segmented-control';
import { FieldControl } from '@ee-monorepo/shared/ui/field-control';
import { Select } from '@ee-monorepo/shared/ui/select';
import {
  FormComponentProps,
  MerchantProductsServicesFormData,
  SubmitDataHandle,
} from '@ee-monorepo/shared/utilities/types';
import { formSchema } from './form-validation';
import {
  bizCategoryDefaultData,
  bizLocationCountDefaultData,
  ecommercePlatforms,
  referralSources,
} from './merchant-products-services-form.static';
import { useTranslations } from 'next-intl';
import { useTrackingContext } from '@ee-monorepo/shared/context/tracking';

export type MerchantProductsServicesFormProps = {
  onSaveMerchantData?: (data: MerchantProductsServicesFormData) => void;
} & FormComponentProps<MerchantProductsServicesFormData>;

export const MerchantProductsServicesForm = forwardRef<
  SubmitDataHandle,
  MerchantProductsServicesFormProps
>((props: MerchantProductsServicesFormProps, ref) => {
  //Destructure Form Component props
  const { onValid, onInvalid, formData, onSaveMerchantData = () => {} } = props;

  const t = useTranslations('merchant_products_services_form');
  const { tracking } = useTrackingContext();

  //Prepare form control hooks
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    watch,
    register,
    formState: { isValid },
  } = useForm<MerchantProductsServicesFormData>({
    defaultValues: formData || {
      industry: '',
      industrySubCategory: '',
      industrySubCategoryOther: '',
      offerLocation: '',
      numberOfLocations: '',
      ecommPlatform: '',
      ecommPlatformOther: '',
      website: '',
      referralSource: '',
    },
    mode: 'all',
    resolver: yupResolver(formSchema),
  });

  const businessServiceMode = watch('offerLocation');
  const ecommercePlatform = watch('ecommPlatform');
  const industry = watch('activeBussinessCategoryIndex');
  const onlineOrBothSelected = ['ONLINE', 'BOTH'].includes(businessServiceMode);
  const isEcommercePlateformRequired = ![
    '',
    null,
    undefined,
    'IN_STORE',
  ].includes(businessServiceMode);

  const selectedCategory = Object.keys(bizCategoryDefaultData)[industry];

  //Initialize Local State for current form fields
  const [category, setCategory] = useState(selectedCategory || '');
  const [subCategoryList, setSubCategoryList] = useState<
    { ui: string; api: string }[]
  >(
    bizCategoryDefaultData[selectedCategory]?.subCategory as {
      ui: string;
      api: string;
    }[]
  );

  const categoryLength: number = Object.keys(bizCategoryDefaultData).length - 1;
  //Bussines Category on select function
  const bizCategoryOnSelect = (index: number) => {
    const bizCatLabel: string = Object.keys(bizCategoryDefaultData)[index];
    setCategory(bizCatLabel);
    setSubCategoryList(
      bizCategoryDefaultData[bizCatLabel].subCategory as {
        ui: string;
        api: string;
      }[]
    );
    setValue('industry', bizCategoryDefaultData[bizCatLabel].api);
    setValue('activeBussinessCategoryIndex', index);
    setValue('industrySubCategory', '');
    setValue('industrySubCategoryOther', '');
    setValue('offerLocation', '');
    setValue('numberOfLocations', '');
    setValue('activebusinessLocationsCountIndex', -1);
    setValue('ecommPlatform', '');
    setValue('ecommPlatformOther', '');
    setValue('website', '');
    reset(getValues());
    tracking?.logClickEvent({
      click_value: t(bizCatLabel),
      click_type: 'button',
      placement: 'new merchant application modal',
    });
  };

  //Location count on select function
  const bizLocationCountOnSelect = (index: number) => {
    setValue('numberOfLocations', bizLocationCountDefaultData[index].api);
    setValue('activebusinessLocationsCountIndex', index);
    reset(getValues());
    tracking?.logClickEvent({
      click_value: bizLocationCountDefaultData[index].ui,
      click_type: 'button',
      placement: 'new merchant application modal',
    });
  };

  useEffect(() => {
    if (isValid && onValid) {
      onValid(getValues());
    }
    if (!isValid && onInvalid) {
      onInvalid(getValues());
    }
  }, [isValid, onValid, onInvalid, getValues]);

  // Set active index for segment controls
  useEffect(() => {
    // Refilling form data when coming back to previous step
    reset(formData);
  }, [formData, reset]);

  const onSubmitHandler = async (data: MerchantProductsServicesFormData) => {
    if (businessServiceMode === 'ONLINE') {
      setValue('numberOfLocations', 'ONE_TO_THREE');
    }
    // TODO: here goes API request
    onSaveMerchantData(data);
  };

  const submitData = handleSubmit(onSubmitHandler);

  useImperativeHandle(
    ref,
    () => {
      return {
        submitData,
      };
    },
    [submitData]
  );

  const offerings: { [key: string]: string } = {
    IN_STORE: t('whereYouOfferItInStore'),
    ONLINE: t('whereYouOfferItOnline'),
    BOTH: t('whereYouOfferItBoth'),
  };

  register('offerLocation', {
    onChange: (e) => {
      tracking?.logClickEvent({
        click_value: offerings[e?.target?.value],
        click_type: 'radio button',
        placement: 'new merchant application modal',
      });
    },
  });

  return (
    <form data-testid="merchant-products-services-form">
      <div>
        <div>
          <h3 className="py-3 px-0">{t('whatYouOffer')}</h3>
        </div>
        <div
          className="md:inline-block"
          data-testid="mpsf-segctrl-category"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <FieldControl
            fieldName="activeBussinessCategoryIndex"
            control={control}
            render={(field, invalid) => (
              <SegmentedControl
                items={Array.from(
                  Object.keys(bizCategoryDefaultData),
                  (e: string) => {
                    return { label: t(e) };
                  }
                )}
                onSelect={bizCategoryOnSelect}
                className="mpsfBizCategoryOption"
                size="normal"
                wrapperClassName="px-0"
                stackOnMobile={true}
                activeItemIndex={field.value}
              />
            )}
          />
        </div>
        <div
          data-testid="dynamic-fields-on-category-selection"
          className="min-h-[18rem]"
        >
          {category && t(category) !== 'Other' ? (
            <div data-testid="when-category-not-other">
              <div className="row mt-3 mx-0">
                <div className="w-full md:w-full px-0">
                  {industry === categoryLength ? (
                    <FieldControl
                      fieldName="industrySubCategoryOther"
                      control={control}
                      render={(field, invalid) => (
                        <TextField
                          {...field}
                          fullWidth
                          label={t('whatYouOfferEnterSubCategory')}
                          error={invalid}
                          data-testid="mpsf-subcategory-other-text"
                          inputProps={{ maxLength: 50 }}
                          onKeyDown={(e) => {
                            e.key === 'Enter' && e.preventDefault();
                          }}
                        />
                      )}
                    />
                  ) : subCategoryList?.length > 0 ? (
                    <FieldControl
                      fieldName="industrySubCategory"
                      control={control}
                      render={(field, invalid) => (
                        <>
                          <InputLabel>
                            {t('whatYouOfferSelectSubCategory')}
                          </InputLabel>

                          <Select
                            {...field}
                            error={invalid}
                            data-testid="mpsf-subcategory-select"
                            {...register('industrySubCategory', {
                              onChange: (e) => {
                                const subcat = subCategoryList.find(
                                  (sc) => sc?.api === e?.target?.value
                                );
                                subcat?.ui &&
                                  tracking?.logClickEvent({
                                    click_value: t(subcat?.ui),
                                    click_type: 'dropdown',
                                    placement: 'new merchant application modal',
                                  });
                              },
                            })}
                          >
                            {subCategoryList?.map(({ ui, api }, _index) => {
                              return (
                                <MenuItem
                                  key={`subcategory-select-${_index}`}
                                  id={`${_index}`}
                                  data-testid={`subcategory-select-${_index}`}
                                  value={api}
                                >
                                  {t(ui)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </>
                      )}
                    />
                  ) : null}
                </div>
              </div>

              <div className="row mx-0">
                <h3 className="py-3 px-0">{t('whereYouOfferIt')}</h3>
              </div>
              <div className="row mx-0">
                <FieldControl
                  fieldName="offerLocation"
                  sx={{
                    paddingLeft: '0 !important',
                  }}
                  control={control}
                  render={(field) => (
                    <RadioGroup
                      row
                      {...field}
                      data-testid="businessServiceMode"
                    >
                      <FormControlLabel
                        label={t('whereYouOfferItInStore')}
                        control={<Radio />}
                        value="IN_STORE"
                        data-testid="businessServiceMode_0"
                      />
                      <FormControlLabel
                        label={t('whereYouOfferItOnline')}
                        control={<Radio />}
                        value="ONLINE"
                        data-testid="businessServiceMode_1"
                      />
                      <FormControlLabel
                        label={t('whereYouOfferItBoth')}
                        control={<Radio />}
                        value="BOTH"
                        data-testid="businessServiceMode_2"
                      />
                    </RadioGroup>
                  )}
                />
                {/* condition - where businessServiceMode === (online|both) */}
                {onlineOrBothSelected && (
                  <div className="snap-row mt-3 px-0">
                    <div className="w-full md:w-full px-0">
                      <FieldControl
                        fieldName="ecommPlatform"
                        control={control}
                        render={(field, invalid) => (
                          <>
                            <InputLabel>
                              {t('selectECommercePlatform')}
                            </InputLabel>
                            <Select
                              {...field}
                              error={invalid}
                              data-testid="mpsf-ecommerce-platform"
                              required={isEcommercePlateformRequired}
                              {...register('ecommPlatform', {
                                onChange: (e) => {
                                  const ecom = ecommercePlatforms.find(
                                    (sc) => sc?.api === e?.target?.value
                                  );
                                  ecom?.ui &&
                                    tracking?.logClickEvent({
                                      click_value: t(ecom?.ui),
                                      click_type: 'dropdown',
                                      placement:
                                        'new merchant application modal',
                                    });
                                },
                              })}
                            >
                              {ecommercePlatforms?.map(
                                ({ ui, api }, _index) => {
                                  return (
                                    <MenuItem
                                      data-testid={`ecommerce-platform-${_index}`}
                                      value={api}
                                      key={t(ui)}
                                    >
                                      {t(ui)}
                                    </MenuItem>
                                  );
                                }
                              )}
                            </Select>
                          </>
                        )}
                      />
                    </div>
                    {/* condition - if e-commerce platform selected as OTHER */}
                    {ecommercePlatform === 'OTHER' && (
                      <div className="w-full md:w-full px-0">
                        <FieldControl
                          fieldName="ecommPlatformOther"
                          control={control}
                          render={(field, invalid) => (
                            <TextField
                              {...field}
                              fullWidth
                              label={t('otherECommercePlatform')}
                              error={invalid}
                              data-testid="otherEcommercePlatformField"
                              required={ecommercePlatform === 'OTHER'}
                              inputProps={{ maxLength: 50 }}
                              onKeyDown={(e) => {
                                e.key === 'Enter' && e.preventDefault();
                              }}
                            />
                          )}
                        />
                      </div>
                    )}
                    <div className="w-full md:w-full px-0">
                      <FieldControl
                        fieldName="website"
                        control={control}
                        render={(field, invalid) => (
                          <TextField
                            {...field}
                            fullWidth
                            required={isEcommercePlateformRequired}
                            label={t('websiteAddress')}
                            error={invalid}
                            type="url"
                            inputProps={{ maxLength: 50 }}
                            onKeyDown={(e) => {
                              e.key === 'Enter' && e.preventDefault();
                            }}
                            data-testid="websiteAddressField"
                          />
                        )}
                      />
                    </div>
                  </div>
                )}
              </div>
              {businessServiceMode !== 'ONLINE' ? (
                <>
                  <div className="row mx-0">
                    <h3 className="py-3 px-0">{t('noOfBusinessLocations')}</h3>
                  </div>
                  <div
                    className="row mx-0 md:inline-block"
                    data-testid="mpsf-segctrl-count"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <FieldControl
                      fieldName="activebusinessLocationsCountIndex"
                      sx={{
                        paddingLeft: '0 !important',
                        paddingRight: '0 !important',
                      }}
                      control={control}
                      render={(field, invalid) => (
                        <SegmentedControl
                          items={Array.from(
                            bizLocationCountDefaultData,
                            ({ ui }) => {
                              return { label: ui };
                            }
                          )}
                          onSelect={bizLocationCountOnSelect}
                          className="mpsfBizLocationCountOption"
                          size="normal"
                          wrapperClassName="px-0"
                          stackOnMobile={true}
                          activeItemIndex={field.value}
                        />
                      )}
                    />
                  </div>
                </>
              ) : null}
              <div className="mt-6 w-full px-0">
                <FieldControl
                  fieldName="referralSource"
                  control={control}
                  render={(field, invalid) => (
                    <>
                      <InputLabel>{t('howDidYouHearAboutSnap')}</InputLabel>

                      <Select
                        {...field}
                        error={invalid}
                        data-testid="mpsf-referral-source"
                        required={true}
                        {...register('referralSource', {
                          onChange: (e) => {
                            const source = referralSources.find(
                              (src) => src?.api === e?.target?.value
                            );
                            source?.ui &&
                              tracking?.logClickEvent({
                                click_value: t(source?.ui),
                                click_type: 'dropdown',
                                placement: 'new merchant application modal',
                              });
                          },
                        })}
                      >
                        {referralSources?.map(({ ui, api }, _index) => {
                          return (
                            <MenuItem
                              data-testid={`referral-source-${_index}`}
                              value={api}
                              key={t(ui)}
                            >
                              {t(ui)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </>
                  )}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </form>
  );
});
