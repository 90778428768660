import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { formSchema } from './form-validation';
import { FieldControl } from '@ee-monorepo/shared/ui/field-control';
import { TextField } from '@ee-monorepo/shared/ui/text-field';
import { FormattedTextField } from './lazy';
import { useTranslations } from 'next-intl';
import { InputLabel, MenuItem } from '@mui/material';
import { Select } from '@ee-monorepo/shared/ui/select';
import {
  bussinessRoleDefaultData,
  bizStateData,
} from './merchant-business-form.static';
import {
  useEffect,
  useImperativeHandle,
  forwardRef,
  useState,
  useRef,
} from 'react';
import {
  FormComponentProps,
  MerchantBusinessFormData,
  SubmitDataHandle,
} from '@ee-monorepo/shared/utilities/types';
import { useTrackingContext } from '@ee-monorepo/shared/context/tracking';
import { AddressAutoComplete } from '@one-snap/sparkles';
import {
  useFetchAddress,
  useFetchAddressSuggestions,
} from '@ee-monorepo/shared/data-access/services';
import cn from 'classnames';

export type MerchantBusinessFormProps = {
  onSaveMerchantData?: (data: MerchantBusinessFormData) => void;
  showManualAddressField?: boolean;
} & FormComponentProps<MerchantBusinessFormData>;

export const MerchantBusinessForm = forwardRef<
  SubmitDataHandle,
  MerchantBusinessFormProps
>((props: MerchantBusinessFormProps, ref) => {
  const {
    showManualAddressField = false,
    onValid,
    onInvalid,
    formData,
    onSaveMerchantData = () => {},
  } = props;

  const t = useTranslations('forms_merchant_business_form');
  const { tracking } = useTrackingContext();
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    register,
    formState: { isValid, errors },
    trigger,
  } = useForm<MerchantBusinessFormData>({
    defaultValues: formData || {
      businessName: '',
      businessRole: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
    },
    mode: 'all',
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    parentRef?.current?.scrollIntoView();
  }, []);

  useEffect(() => {
    if (isValid && onValid) {
      onValid(getValues());
    }
    if (!isValid && onInvalid) {
      onInvalid(getValues());
    }
  }, [isValid, onValid, onInvalid, getValues]);

  // Refilling form data when coming back to previous step
  useEffect(() => {
    reset(formData);
  }, [formData]);

  const onSubmitHandler = (data: MerchantBusinessFormData) => {
    // TODO: here goes API request
    onSaveMerchantData(data);
  };

  const submitData = handleSubmit(onSubmitHandler);

  useImperativeHandle(
    ref,
    () => {
      return {
        submitData,
      };
    },
    [submitData]
  );

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: '60%',
      },
    },
  };

  const { fetchAddressSuggestions } = useFetchAddressSuggestions();
  const { fetchAddress } = useFetchAddress();

  const [addressVerified, setAddressVerified] = useState(false);
  const [addressSelected, setAddressSelected] = useState(false);
  const [addressLoading, setAddressLoading] = useState(false);

  const parentRef = useRef<HTMLFormElement | null>(null);
  const addressRef = useRef<HTMLDivElement | null>(null);

  return (
    <form ref={parentRef} data-testid="merchant-business-form">
      <div className="row pt-[10px] mx-0">
        <div className="w-full md:w-1/2 pl-0 md:pr-8 sm:px-0">
          <FieldControl
            fieldName="businessName"
            control={control}
            render={(field, invalid) => (
              <TextField
                {...field}
                fullWidth
                label={t('businessName')}
                error={invalid}
                data-testid="businessNameField"
                inputProps={{ maxLength: 100 }}
              />
            )}
          />
        </div>
        <div className="w-full md:w-1/2 px-0">
          <FieldControl
            fieldName="businessRole"
            control={control}
            render={(field, invalid) => (
              <>
                <InputLabel>{t('businessRole')}</InputLabel>
                <Select
                  {...field}
                  label={t('businessRole')}
                  error={invalid}
                  data-testid="businessRoleField"
                  {...register('businessRole', {
                    onChange: (e) => {
                      const brole = bussinessRoleDefaultData.find(
                        (sc) => sc?.api === e?.target?.value
                      );
                      brole &&
                        brole?.ui &&
                        tracking?.logClickEvent({
                          click_value: t(brole?.ui),
                          click_type: 'dropdown',
                          placement: 'new merchant application modal',
                        });
                    },
                  })}
                >
                  {bussinessRoleDefaultData?.map(({ ui, api }, _index) => {
                    return (
                      <MenuItem
                        id={`${_index}`}
                        data-testid={`businessRoleField_${_index}`}
                        value={api}
                        key={ui}
                      >
                        {t(ui)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </>
            )}
          />
        </div>
        <div className="w-full md:w-1/2 pl-0 md:pr-8 sm:px-0">
          <FieldControl
            fieldName="firstName"
            control={control}
            render={(field, invalid) => (
              <TextField
                {...field}
                fullWidth
                label={t('firstName')}
                error={invalid}
                data-testid="firstNameField"
                inputProps={{ maxLength: 100 }}
              />
            )}
          />
        </div>
        <div className="w-full md:w-1/2 px-0">
          <FieldControl
            fieldName="lastName"
            control={control}
            render={(field, invalid) => (
              <TextField
                {...field}
                fullWidth
                label={t('lastName')}
                error={invalid}
                data-testid="lastNameField"
                inputProps={{ maxLength: 100 }}
              />
            )}
          />
        </div>
        <div className="w-full md:w-1/2 pl-0 md:pr-8 sm:px-0">
          <FieldControl
            fieldName="email"
            control={control}
            render={(field, invalid) => (
              <TextField
                {...field}
                fullWidth
                label={t('email')}
                error={invalid}
                data-testid="emailField"
                inputProps={{ maxLength: 50 }}
              />
            )}
          />
        </div>
        <div className="w-full md:w-1/2 px-0">
          <FieldControl
            fieldName="phoneNumber"
            control={control}
            render={(field, invalid) => (
              <FormattedTextField
                {...field}
                fullWidth
                label={t('phoneNumber')}
                error={invalid}
                mask="000-000-0000"
                isPassword={false}
                maskedValue={false}
                data-testid="phoneNumberField"
                inputProps={{ maxLength: 10 }}
              />
            )}
          />
        </div>
        <div className="w-full px-0">
          <div ref={addressRef} className="flex h-0 w-0"></div>
          {formData?.manualDataEntered ? (
            <FieldControl
              fieldName="address"
              control={control}
              render={(field, invalid) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('address')}
                  error={invalid}
                  data-testid="addressField"
                  inputProps={{ maxLength: 150 }}
                />
              )}
            />
          ) : (
            <FieldControl
              fieldName="address"
              control={control}
              render={(field, invalid) => (
                <div
                  data-testid="address-auto-complete"
                  className={cn(
                    '[&>div]:max-w-full [&>div>div]:max-w-full [&_ul]:max-h-[25vh] md:[&_ul]:max-h-[75vh]',
                    {
                      '[&>div]:h-[40vh] md:[&>div]:h-[80vh]':
                        (addressLoading || (!addressSelected && !invalid)) &&
                        !formData?.manualDataEntered,
                      '[&>div]:h-[55px]': invalid,
                    }
                  )}
                >
                  <AddressAutoComplete
                    {...field}
                    label={t('address')}
                    onChange={(inputValue) => {
                      addressRef?.current?.scrollIntoView();
                      setValue('address', String(inputValue));
                      setAddressSelected(false);
                      setAddressVerified(false);
                      trigger(['address']);
                    }}
                    fetchAddresses={fetchAddressSuggestions}
                    onFooterItemSelect={() => {
                      const formData = getValues();
                      onSaveMerchantData({
                        ...formData,
                        manualDataEntered: true,
                        address: '',
                        state: '',
                        city: '',
                        zipCode: '',
                      } as MerchantBusinessFormData);
                    }}
                    onResultItemSelect={async (value) => {
                      parentRef?.current?.scrollIntoView();
                      setAddressSelected(true);
                      setValue('address', String(value.value));
                      setAddressLoading(true);
                      const result = await fetchAddress({
                        global_address_key: value.key,
                      });
                      if (result) {
                        if (result.confidence === 'Verified match') {
                          const { city, state, zipCode } = result.address;
                          setAddressVerified(true);
                          setValue('city', city);
                          setValue('state', state);
                          setValue('zipCode', zipCode);
                        }
                      }
                      setAddressLoading(false);
                      trigger(['address', 'city', 'state', 'zipCode']);
                    }}
                    onBlur={(event) => {
                      event.target.value = '';
                    }}
                    debounceDuration={500}
                    error={invalid}
                  />
                </div>
              )}
            />
          )}
        </div>
      </div>
      {((addressSelected && !addressLoading && !addressVerified) ||
        formData?.manualDataEntered) && (
        <div className="row mx-0">
          <div className="md:w-1/3 md:pr-2 px-0">
            <FieldControl
              fieldName="city"
              control={control}
              render={(field, invalid) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('city')}
                  error={invalid}
                  data-testid="cityField"
                  inputProps={{ maxLength: 50 }}
                />
              )}
            />
          </div>
          <div className="md:w-1/3 md:pr-2 sm:px-0">
            <FieldControl
              fieldName="state"
              control={control}
              render={(field, invalid) => (
                <>
                  <InputLabel>{t('state')}</InputLabel>
                  <Select
                    {...field}
                    label={t('state')}
                    error={invalid}
                    data-testid="stateField"
                    menuStyles={{ maxHeight: '40%' }}
                    {...register('state', {
                      onChange: (e) => {
                        const state = bizStateData.find(
                          (sc) => sc?.state_code === e?.target?.value
                        );
                        state?.state_name &&
                          tracking?.logClickEvent({
                            click_value: state?.state_name,
                            click_type: 'dropdown',
                            placement: 'new merchant application modal',
                          });
                      },
                    })}
                  >
                    {bizStateData?.map(
                      (
                        state: { state_code: string; state_name: string },
                        _index
                      ) => {
                        const { state_code, state_name } = state;
                        return (
                          <MenuItem
                            id={`${_index}`}
                            data-testid={`stateField_${_index}`}
                            value={state_code}
                            key={state_code}
                          >
                            {state_name}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </>
              )}
            />
          </div>
          <div className="md:w-1/3 px-0">
            <FieldControl
              fieldName="zipCode"
              control={control}
              render={(field, invalid) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t('zipCode')}
                  error={invalid}
                  data-testid="zipCodeField"
                  inputProps={{ maxLength: 5 }}
                />
              )}
            />
          </div>
        </div>
      )}
    </form>
  );
});
