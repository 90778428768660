export const bizCategoryDefaultData: {
  [key: string]: { api: string; subCategory: { ui?: string; api?: string }[] };
} = {
  categoryAutomotive: {
    api: 'AUTOMOTIVE',
    subCategory: [
      { ui: 'subcatAutobatteries', api: 'AUTO_BATTERIES' },
      { ui: 'subcatCollision', api: 'COLLISION_REPAIR' },
      { ui: 'subcatAutopaint', api: 'AUTO_PAINT' },
      { ui: 'subcatAutoparts', api: 'AUTO_PARTS' },
      { ui: 'subcatAutoservice', api: 'AUTO_SERVICE' },
      { ui: 'subcatCaraudio', api: 'CAR_AUDIO' },
      { ui: 'subcatCarwindowtints', api: 'CAR_WINDOW_TINTS' },
      { ui: 'subcatColorvehiclewraps', api: 'COLOR_VEHICLE_WRAPS' },
      { ui: 'subcatCommercialtires', api: 'COMMERCIAL_TIRES' },
      { ui: 'subcatElectronicscootersbikes', api: 'ELECTRONIC_SCOOTERS_BIKES' },
      {
        ui: 'subcatMotorcycleatvutvpartsservice',
        api: 'MOTORCYCLE_ATV_UTV_PARTS_SERVICE',
      },
      { ui: 'subcatTirenrims', api: 'TIRES_RIMS' },
    ],
  },
  categoryElectronics: {
    api: 'ELECTRONICS',
    subCategory: [
      { ui: 'subcatGamingconsoles', api: 'GAMING_CONSOLES' },
      { ui: 'subcatHomeaudio', api: 'HOME_AUDIO' },
      { ui: 'subcatLaptopscomputerstablets', api: 'LAPTOPS_COMPUTERS_TABLETS' },
      { ui: 'subcatTvs', api: 'TVS' },
    ],
  },
  categoryRetail: {
    api: 'RETAIL',
    subCategory: [
      { ui: 'subcatAppliances', api: 'APPLIANCES' },
      { ui: 'subcatBbqgrills', api: 'BBQ_GRILLS' },
      { ui: 'subcatBicycles', api: 'BICYCLES' },
      { ui: 'subcatExerciseequipment', api: 'EXERCISE_EQUIPMENT' },
      { ui: 'subcatFurniture', api: 'FURNITURE' },
      { ui: 'subcatHvac', api: 'HVAC' },
      { ui: 'subcatJewelry', api: 'JEWELRY' },
      { ui: 'subcatLawnngarden', api: 'LAWN_AND_GARDEN' },
      { ui: 'subcatMattress', api: 'MATTRESS' },
      { ui: 'subcatMusicalinstruments', api: 'MUSICAL_INSTRUMENTS' },
      { ui: 'subcatPowertools', api: 'POWER_TOOLS' },
      {
        ui: 'subcatSecuritysystemshardwareonly',
        api: 'SECURITY_SYSTEMS_HARDWARE_ONLY',
      },
      { ui: 'subcatSportinggoods', api: 'SPORTING_GOODS' },
    ],
  },
  categoryOther: { api: 'OTHER', subCategory: [] },
};

export const ecommercePlatforms: { ui: string; api: string }[] = [
  { ui: 'shopify', api: 'SHOPIFY' },
  { ui: 'wooCommerce', api: 'WOOCOMMERCE' },
  { ui: 'bigCommerce', api: 'BIGCOMMERCE' },
  { ui: 'magento', api: 'MAGENTO' },
  { ui: 'other', api: 'OTHER' },
];

export const bizLocationCountDefaultData: { ui: string; api: string }[] = [
  { ui: '1-4', api: 'ONE_TO_FOUR' },
  { ui: '5-24', api: 'FIVE_TO_TWENTY_FOUR' },
  { ui: '25+', api: 'MORE_THAN_TWENTY_FIVE' },
];

export const referralSources: { ui: string; api: string }[] = [
  { ui: 'refsrcSnapSalesRep', api: 'SNAP_SALES_REP' },
  { ui: 'refsrcBusinessUsingSnap', api: 'BUSINESS_USING_SNAP' },
  { ui: 'refsrcFriendOrFamilyMember', api: 'FRIEND_OR_FAMILY_MEMBER' },
  { ui: 'refsrcCustomer', api: 'CUSTOMER' },
  { ui: 'refsrcGoogleSearch', api: 'GOOGLE_SEARCH' },
  { ui: 'refsrcOnlineAd', api: 'ONLINE_AD' },
  { ui: 'refsrcDirectMail', api: 'DIRECT_MAIL' },
  { ui: 'refsrcTradeShowOrEvent', api: 'TRADE_SHOW_OR_EVENT' },
  { ui: 'refsrcEmail', api: 'EMAIL' },
  {
    ui: 'refsrcMagazineOrTradePublication',
    api: 'MAGAZINE_OR_TRADE_PUBLICATION',
  },
  { ui: 'refsrcNewsStory', api: 'NEWS_STORY' },
  { ui: 'refsrcOther', api: 'OTHER' },
];
