import * as yup from 'yup';
import {
  EMAIL,
  ZIP_CODE,
  REQUIRED,
  PHONE_LENGTH,
  MerchantBusinessFormData,
} from '@ee-monorepo/shared/utilities/types';

export const formSchema: yup.SchemaOf<MerchantBusinessFormData> = yup
  .object()
  .shape({
    businessName: yup.string().required(REQUIRED).clearParams(),
    businessRole: yup.string().required(REQUIRED),
    firstName: yup.string().required(REQUIRED).clearParams(),
    lastName: yup.string().required(REQUIRED).clearParams(),
    email: yup.string().required(REQUIRED).email(EMAIL),
    phoneNumber: yup
      .string()
      .required(REQUIRED)
      .digitsOnly()
      .length(10, PHONE_LENGTH),
    address: yup.string().required(REQUIRED).clearParams(),
    city: yup.string().required(REQUIRED).clearParams(),
    state: yup.string().required(REQUIRED),
    zipCode: yup
      .string()
      .required(REQUIRED)
      .digitsOnly()
      .length(5, ZIP_CODE)
      .clearParams(),
    manualDataEntered: yup.boolean().notRequired(),
  });
