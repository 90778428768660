import { Dispatch, SetStateAction, forwardRef } from 'react';
import { MerchantBusinessForm } from '@ee-monorepo/forms/merchant-business-form';
import { MerchantProductsServicesForm } from '@ee-monorepo/forms/merchant-products-services-form';
import { MerchantFinancialForm } from '@ee-monorepo/forms/merchant-financial-form';
import { MerchantShortForm } from '@ee-monorepo/forms/merchant-short-form';
import { InlineStatusMessage } from '@ee-monorepo/shared/ui/inline-status-message';
import {
  MerchantBusinessFormData,
  MerchantFinancialFormData,
  MerchantProductsServicesFormData,
  SubmitDataHandle,
} from '@ee-monorepo/shared/utilities/types';
import { useTranslations } from 'next-intl';

export interface ConditionalRenderedFormProps {
  step: number;
  setOnValid: () => void;
  setOnInValid: () => void;
  finalData: any;
  isCategoryOther: boolean;
  submitError: boolean;
  onSaveMerchantDataHandler: (
    formData:
      | MerchantProductsServicesFormData
      | MerchantBusinessFormData
      | MerchantFinancialFormData
  ) => void;
  setSubmitSuccess: Dispatch<SetStateAction<boolean>>;
  setSubmitError: Dispatch<SetStateAction<boolean>>;
}

const ConditionalRenderedForm = forwardRef<
  SubmitDataHandle,
  ConditionalRenderedFormProps
>((props: ConditionalRenderedFormProps, ref) => {
  const t = useTranslations('dialogs_merchant_long_form_dialog');
  const {
    step,
    setOnValid,
    setOnInValid,
    finalData,
    isCategoryOther,
    submitError,
    onSaveMerchantDataHandler,
    setSubmitSuccess,
    setSubmitError,
  } = props;
  return (
    <div data-testid="conditionalRenderedForm">
      {step === 0 && (
        <div data-testid="merchantProductsServicesForm">
          <MerchantProductsServicesForm
            onValid={setOnValid}
            onInvalid={setOnInValid}
            ref={ref}
            onSaveMerchantData={onSaveMerchantDataHandler}
            formData={finalData.ProductServiceData}
          />
        </div>
      )}
      {step === 1 && isCategoryOther && (
        <div>
          {submitError && (
            <div className="pt-4">
              <InlineStatusMessage
                alertType="error"
                text={t('formSubmissionFailed')}
                setVisible={setSubmitError}
                visible={submitError}
                alertTitle={t('formSubmissionFailedTitle')}
              />
            </div>
          )}
          <h2
            className="headingM md:mt-0 md:mb-6 sm:mt-5 sm:mb-7 text-SnapGrey500"
            data-testid="dialogHeaderText"
          >
            {t('headerText')}
          </h2>
          <div>
            <MerchantShortForm
              onSubmitSuccess={setSubmitSuccess}
              onSubmitError={setSubmitError}
            />
          </div>
        </div>
      )}
      {step === 1 && !isCategoryOther && (
        <div data-testid="merchantBusinessForm">
          <MerchantBusinessForm
            onValid={setOnValid}
            onInvalid={setOnInValid}
            ref={ref}
            onSaveMerchantData={onSaveMerchantDataHandler}
            formData={finalData.BusinessData}
          />
        </div>
      )}
      {step === 2 && (
        <div data-testid="merchantFinancialForm">
          {submitError && (
            <div className="pt-4">
              <InlineStatusMessage
                alertType="error"
                text={t('formSubmissionFailed')}
                setVisible={setSubmitError}
                visible={submitError}
                alertTitle={t('formSubmissionFailedTitle')}
              />
            </div>
          )}
          <MerchantFinancialForm
            onValid={setOnValid}
            onInvalid={setOnInValid}
            ref={ref}
            onSaveMerchantData={onSaveMerchantDataHandler}
            formData={finalData.FinancialData}
          />
        </div>
      )}
      <div className="h-20"></div>
    </div>
  );
});

export default ConditionalRenderedForm;
