import { useTrackingContext } from '@ee-monorepo/shared/context/tracking';
import cn from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import s from './segmented-control.module.scss';

export interface SegmentedControlProps {
  items: {
    iconComponent?: JSX.Element;
    label: string;
    disabled?: boolean;
  }[];
  onSelect: (i: number) => void;
  activeItemIndex?: number;
  className?: string;
  size?: 'normal' | 'compact';
  wrapperClassName?: string;
  stackOnMobile?: boolean;
  customIndex?: number;
}

export function SegmentedControl(props: SegmentedControlProps) {
  const {
    onSelect,
    items,
    activeItemIndex,
    className,
    size,
    wrapperClassName,
    stackOnMobile,
    customIndex,
  } = props;
  const [currentItemIndex, setCurrentItemIndex] = useState(activeItemIndex);
  const { tracking } = useTrackingContext();

  const onSegmentClick = useCallback(
    (index: number) => {
      setCurrentItemIndex(index);
      onSelect(index);
      tracking?.logClickEvent({
        click_type: 'button',
        placement: 'body',
        click_value: index === 0 ? 'in-store' : 'online',
      });
    },
    [onSelect, tracking]
  );

  useEffect(() => {
    if (customIndex && customIndex > -1) {
      onSegmentClick(customIndex);
    }
  }, [customIndex, onSegmentClick]);

  useEffect(() => {
    setCurrentItemIndex(activeItemIndex);
  }, [activeItemIndex]);

  return (
    <div
      className={cn(s.wrapper, wrapperClassName)}
      data-testid="segmented-control"
    >
      {items.map((item, index) => (
        <button
          onClick={() => !item.disabled && onSegmentClick(index)}
          key={index}
          type="button"
          disabled={index === currentItemIndex}
          className={cn(
            s.button,
            'secondaryHeavyFont',
            {
              [s.activeButton]: !item.disabled && index === currentItemIndex,
              [s.buttonSizeCompact]: size === 'compact',
              [s.buttonDisable]: item.disabled,
              [s.segmentedMobile]: stackOnMobile === true,
            },
            className
          )}
        >
          <div className="flex justify-center items-center">
            {item.iconComponent}
            <span className="pl-2">{item.label}</span>
          </div>
        </button>
      ))}
    </div>
  );
}

export default SegmentedControl;
