import * as yup from 'yup';

import {
  MerchantProductsServicesFormData,
  REQUIRED,
} from '@ee-monorepo/shared/utilities/types';

export const formSchema: yup.SchemaOf<MerchantProductsServicesFormData> = yup
  .object()
  .shape({
    activeBussinessCategoryIndex: yup.number().required(REQUIRED),
    industry: yup.string().required(REQUIRED),
    industrySubCategory: yup.string().when('industry', (val: string) => {
      return val === 'OTHER'
        ? yup.string().notRequired()
        : yup.string().required(REQUIRED);
    }),
    industrySubCategoryOther: yup.string().notRequired(),
    offerLocation: yup.string().when('industry', (val: string) => {
      return val !== 'OTHER'
        ? yup.string().required(REQUIRED)
        : yup.string().notRequired();
    }),
    numberOfLocations: yup.string().when('offerLocation', (val: string) => {
      return val === 'ONLINE' || val === ''
        ? yup.string().notRequired()
        : yup.string().required(REQUIRED);
    }),
    activebusinessLocationsCountIndex: yup
      .number()
      .when('offerLocation', (val: string) => {
        return val === 'ONLINE' || val === ''
          ? yup.number().notRequired()
          : yup.number().required(REQUIRED);
      }),
    ecommPlatform: yup.string().when('offerLocation', (val: string) => {
      return val === 'ONLINE' || val === 'BOTH'
        ? yup.string().required(REQUIRED)
        : yup.string().notRequired();
    }),
    ecommPlatformOther: yup.string().when('ecommPlatform', (val: string) => {
      return val === 'OTHER'
        ? yup.string().required(REQUIRED).clearParams()
        : yup.string().notRequired();
    }),
    website: yup.string().when('offerLocation', (val: string) => {
      return val === 'ONLINE' || val === 'BOTH'
        ? yup.string().required(REQUIRED).websiteAddressValidation()
        : yup.string().notRequired();
    }),
    referralSource: yup.string().when('industry', (val: string) => {
      return val !== 'OTHER'
        ? yup.string().required(REQUIRED)
        : yup.string().notRequired();
    }),
  });
