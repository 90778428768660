import { createTranslations } from '@ee-monorepo/shared/utilities/functions';

export * from './lib/merchant-long-form-dialog';

// form module translation
export const getTranslations = createTranslations(
  'dialogs_merchant_long_form_dialog',
  (locale) => {
    return require(`./i18n/${locale}.json`);
  }
);

