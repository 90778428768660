import { createTranslations } from '@ee-monorepo/shared/utilities/functions';

export * from './lib/merchant-products-services-form';
export * from './lib/form-validation'
export * from './lib/merchant-products-services-form.static'

// form module translation
export const getTranslations = createTranslations(
  'merchant_products_services_form',
  (locale) => {
    return require(`./i18n/${locale}.json`);
  }
);
